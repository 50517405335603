import React, { useEffect, useState } from "react";

import Modalwrapper from "../../common/modal";
import Selector from "../../common/selector";
import Button from "../../common/button";
import TextArea from "../../common/formElements/textarea";
import Input from "../../common/formElements/input";
import axios from "axios";
import { baseUrl } from "../../../helpers/api";
import Loader from "../../images/loader";
import TrashIcon from "../../images/TrashIcon";

const capitalize = s => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const ParseIngredient = ({ customLink, onCloseCustomModal, addToGroceryList }) => {

    const [linkError, setLinkError] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const [ingredients, setIngredients] = useState([]);
    useEffect(() => {
        setIsFetching(true);
        axios({
            method: 'POST',
            url: `${baseUrl}/api/v1/calendar/parse-ingredient-from-external`,
            data: {
                url: customLink || 'https://www.the-girl-who-ate-everything.com/keto-egg-roll-in-a-bowl/'
            }
        }).then((response) => {
            // console.log('ingredients at fetch', response);
            setIngredients(response.data.response.ingredientWithCategories);
            setIsFetching(false);

        }).catch(e => {
            setIsFetching(false);
            linkError("Could not parse ingredients");
        })

    }, [])

    const addIngredientsToGroceryList = () => {
        const ingredientData = ingredients.map((ing) => ({
            ingredient: {
                display_singular_name: ing.ingredient,
                category: ing.category,
                isId: true
            },


        }));

        addToGroceryList(ingredientData)
        onCloseCustomModal();
    }

    // console.log('ingredients ', ingredients);

    return (
        <Modalwrapper minHeight={(!isFetching && (linkError || ingredients.length === 0)) ? "300px" : "520px"} maxWidth="750px" isOpen={true} onRequestClose={onCloseCustomModal}>
            <div className="modalWrapper custom-modal-wrapper ingredients-chooser">
                {
                    (!isFetching && (linkError || ingredients.length === 0)) ? (
                        <>
                            <div className="modalHeader">
                                <h4>Unable to Parse Ingredients</h4>
                            </div>
                            <div className="modalBody custom-meal-wrapper" style={{ marginTop: 50 }}>
                                We are currently unable to parse the ingredients as it is not openly available.
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="modalHeader">
                                <h4>Add to GroceryList</h4>
                            </div>
                            {
                                isFetching ?
                                    <div className="modalBody custom-meal-wrapper">
                                        <div className="custom-block">
                                            <div className="preLoader">
                                                <Loader />
                                            </div>
                                        </div>
                                    </div>
                                    : (
                                        <>
                                            <div className="modalBody custom-meal-wrapper" style={{ bottom: 120 }}>
                                                <div className="custom-block">
                                                    <form>
                                                        {
                                                            ingredients.map((ing, index) => {
                                                                return (
                                                                    <div
                                                                        key={ing.ingredient}
                                                                        style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'space-between',
                                                                            alignItems: 'center',
                                                                        }}>
                                                                        <div
                                                                            className="form-text trash-icon" style={{
                                                                                maxWidth: 15,
                                                                                marginRight: 10,
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            onClick={e => {
                                                                                const updatedIngredients = ingredients.filter(i => {
                                                                                    return (i.ingredient !== ing.ingredient)
                                                                                });
                                                                                setIngredients(updatedIngredients)
                                                                            }}
                                                                        >
                                                                            <TrashIcon />
                                                                        </div>
                                                                        <div className="form-text">

                                                                            <Input
                                                                                type="text"
                                                                                placeholder={`Add Recipe`}
                                                                                className="inputField"
                                                                                label="Title"
                                                                                value={ing.ingredient}
                                                                                onChange={e => {
                                                                                    const updatedIngredients = ingredients.map(i => {
                                                                                        if (i.ingredient === ing.ingredient) {
                                                                                            return {
                                                                                                ...i,
                                                                                                ingredient: e.target.value
                                                                                            }
                                                                                        } else {
                                                                                            return i;
                                                                                        }
                                                                                    });
                                                                                    setIngredients(updatedIngredients)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="selector">

                                                                            <Selector
                                                                                selectorList={[
                                                                                    { title: "Speciality", value: 11 },
                                                                                    { title: "Miscellaneous", value: 12, },
                                                                                    { title: "Frozen", value: 8, },
                                                                                    { title: "Nuts & Nut Butters", value: 14, },
                                                                                    { title: "Condiments, Dressings, & Oils", value: 3, },
                                                                                    { title: "Seasonings", value: 10, },
                                                                                    { title: "Baking & Cooking", value: 9, },
                                                                                    { title: "Boxed, Jarred, & Canned", value: 5, },
                                                                                    { title: "Bread & Baked Goods", value: 2, },
                                                                                    { title: "Meat & Seafood", value: 4, },
                                                                                    { title: "Grains & Pasta", value: 6, },
                                                                                    { title: "Dairy & eggs", value: 7, },
                                                                                    { title: "Uncategorized", value: 15, },
                                                                                    { title: "Produce", value: 1 },
                                                                                ]}
                                                                                onSelectValue={(value) => {
                                                                                    const updatedIngredients = ingredients.map(i => {
                                                                                        if (i.ingredient === ing.ingredient) {
                                                                                            return {
                                                                                                ...i,
                                                                                                category: value
                                                                                            }
                                                                                        } else {
                                                                                            return i;
                                                                                        }
                                                                                    });
                                                                                    setIngredients(updatedIngredients)
                                                                                }}
                                                                                value={ing.category}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )

                                                            })
                                                        }

                                                    </form>
                                                </div>

                                            </div>
                                            <div className="modalFooter">
                                                <Button
                                                    type="button"
                                                    btnClass="primary btn-full"
                                                    btnText="Add to List"
                                                    onClick={addIngredientsToGroceryList}
                                                />
                                                <Button btnClass="secondary btn-full btn-bp-sm" btnText="Cancel" onClick={() => onCloseCustomModal()} />
                                            </div>
                                        </>
                                    )
                            }
                        </>
                    )
                }


            </div>
        </Modalwrapper>
    );
};

export default ParseIngredient;
