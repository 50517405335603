import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Row, Col } from "react-flexbox-grid";
import cx from "classnames";
import queryString from "query-string";
import Radiobox from "../../common/radiobox";
import axios from "axios";
import Swiper from "react-id-swiper";

import toJS from "../../../helpers/toJS";
import Loader from "../../images/loader";
import { getInitiatedEmail, getRegisterStatus } from "../../../selectors";
import {
  assignPlanUnified,
  showToastMessage,
  appEssentialFetch,
  couponActivate,
  setPlans,
} from "../../../actions";
import PlanBenefitCard from "./planBenefitCard";
import katie from "../../images/register-katie.png";
import {
  TransitionOne,
  TransitionEaseInOne,
  TransitionEaseInTwo,
} from "../../common/transitions";
import MostPopularLabel from "../../images/mostPopular";

import { auth } from "../../../helpers/api";
import { duration } from "moment";
import Modalwrapper from "../../common/modal";
import Button from "../../common/button";

const ChoosePlan = ({
  assignPlanUnified,
  history,
  plans: prefetchedPlans = [],
  registerStatus,
  appEssentialFetch,
  couponActivateInitiate,
  couponActivateSuccess,
  couponActivateFailure,
  unifiedRegisterState,
  showToastMessage,
  setPlans: setPlansOnRedux,
  isAuthenticated,
  ...props
}) => {
  const [plans, setPlans] = useState(prefetchedPlans);
  const [isPlansFetching, setPlansFetching] = useState(false);

  const queryParam = props.location.search;

  if (isAuthenticated) {
    history.push("/recipes");
  }

  let queryParamObject = {};
  if (queryParam) {
    queryParamObject = queryString.parse(queryParam, { arrayFormat: "comma" });
  }

  useEffect(() => {
    if (prefetchedPlans.length < 2) {
      setPlansFetching(true);
      axios({
        method: "GET",
        url: auth.getPlans(true),
      })
        .then((response) => {
          setPlansFetching(true);
          setPlans(response.data);
          setPlansOnRedux(response.data);
        })
        .catch((e) => {
          setPlansFetching(false);
          // props.showToastMessage(`Something went wrong`, "error");
          const eventId = pushToSentry(e);
        });
    }
  }, []);

  useEffect(() => {
    if (window.location.href.includes('pricing')) {
      localStorage.removeItem("token");
    }
  }, []);


  // For gift code
  useEffect(() => {
    // Navigate to complete registration
    if (unifiedRegisterState.gift.data.giftCode) {
      history.push("/register");
    }
  }, [unifiedRegisterState.gift.data.giftCode]);

  // For coupon activation
  const params = (slidesPerView = 3) => ({
    slidesPerView: slidesPerView,
    spaceBetween: 0,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      1024: {
        slidesPerView: slidesPerView,
      },
      768: {
        slidesPerView: slidesPerView,
      },
      640: {
        slidesPerView: 1,
      },
      320: {
        slidesPerView: 1,
      },
      300: {
        slidesPerView: 1,
      },
    },
  });

  const [billingDuration, setBillingDuration] = useState("monthly");
  const [couponWarningModal, setCouponWarningModal] = useState(false);
  useEffect(() => {
    if (billingDuration === 'monthly' && unifiedRegisterState.coupon.activationSuccess) {
      setCouponWarningModal(true);
    }
  }, [billingDuration]);
  useEffect(() => {
    if (queryParamObject.promo) {
      couponActivateInitiate();
      axios({
        method: "POST",
        url: auth.validatePromo(),
        data: {
          code: queryParamObject.promo,
        },
        headers: {
          Authorization: `Token token="${localStorage.getItem("token")}"`,
        },
      })
        .then((response) => {
          if (response.data.valid) {
            showToastMessage("✅ Coupon code applied.");
            couponActivateSuccess(response.data.coupon);
            setBillingDuration("annually");
          }
        })
        .catch((e) => {
          showToastMessage("❗️Coupon code seems to be invalid");
          couponActivateFailure();
        });
    }
  }, []);



  const [plan, setPlan] = useState("premiumPlan");

  const onChoosePlan = (activePlan) => {
    assignPlanUnified(activePlan, billingDuration);

    history.push("/initiate-register");
  };

  const onClickPlanCard = (plan) => {
    setPlan(plan);
  };
  useEffect(() => {
    if (registerStatus.assignedToPlan) {
      history.push("/register");
    }
  }, [registerStatus.assignedToPlan]);

  const getTitle = (planType) => {
    const premiumPlanIndex = plans.findIndex((plan) => plan.order === 1);
    const basePlanIndex = plans.findIndex((plan) => plan.order === 2);
    const cornerPlanIndex = plans.findIndex((plan) => plan.order === 3);
    if (plans.length > 0) {
      if (planType === 'premium') {
        return plans[premiumPlanIndex].name;
      } else if (planType === 'base') {
        return plans[basePlanIndex].name;
      } else if (planType === 'corner') {
        return plans[cornerPlanIndex].name;
      }
    }
  };
  const getPrice = (planType) => {
    const premiumPlanIndex = plans.findIndex((plan) => plan.order === 1);
    const basePlanIndex = plans.findIndex((plan) => plan.order === 2);

    let normalPrice = null;
    if (plans.length > 0) {
      if (planType === 'premium') {
        normalPrice =
          billingDuration === "annually"
            ? plans[premiumPlanIndex].annual_price / 100
            : plans[premiumPlanIndex].monthly_price / 100;
      } else if (planType === 'base') {
        normalPrice =
          billingDuration === "annually"
            ? plans[basePlanIndex].annual_price / 100
            : plans[basePlanIndex].monthly_price / 100;
      }
    }

    return normalPrice;
  };


  if (
    plans &&
    plans.length < 2 ||
    registerStatus.isConfirming ||
    unifiedRegisterState.coupon.isActivating
  ) {
    return (
      <div className="container">
        <div className="preLoader">
          <Loader />
        </div>
      </div>
    );
  }

  const premiumPlanIndex = plans.findIndex((plan) => plan.order === 1);
  const basePlanIndex = plans.findIndex((plan) => plan.order === 2);

  const premiumPlanId = plans[premiumPlanIndex].id;
  const basePlanId = plans[basePlanIndex].id;

  return (
    <>
      <section className="choose-plan-section">
        <Helmet>
          {queryParamObject.type == "view" ? (
            <title> Plans & Pricing | Dashing Dish </title>
          ) : (
            <title>Select a Plan | Dashing Dish</title>
          )}
          <meta name="description" content="Dashingdish Choose Plan page" />
        </Helmet>

        <Row className="choose-plan no-margin">
          <TransitionOne>
            <Col xs={12} className="no-padding-sm">
              <div className="login-wrapper">
                <div className="login-wrapper-content">
                  <h1 className="md-center">Plans and Pricing</h1>
                  <p>
                    <span>Save over 30% &nbsp;</span>when you select annual
                    billing
                  </p>
                  <div className="billing-duration choose-plan">
                    <div className="billing-radioBox">
                      <Radiobox
                        type="checkbox"
                        label={"Monthly"}
                        checked={billingDuration === "monthly"}
                        onSelect={() => setBillingDuration("monthly")}
                        radiobox
                      />
                    </div>
                    <div className="billing-radioBox">
                      <Radiobox
                        type="checkbox"
                        label={"Annual"}
                        checked={billingDuration === "annually"}
                        onSelect={() => setBillingDuration("annually")}
                        radiobox
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </TransitionOne>
          <Modalwrapper
            isOpen={couponWarningModal}
            maxWidth='600px'
            minHeight='350px'
            onRequestClose={() => {
              setCouponWarningModal(false);
            }}
          >
            <div className="modalWrapper">
              <div class="modalHeader warning-head">
                <h4>Attention</h4>
              </div>
              <div className="modalBody">
                <p>
                  <ul>
                    <li style={{ textAlign: 'left' }}>This coupon is applicable only for Annual plans.</li>
                    <li style={{ textAlign: 'left' }}>If you proceed with Monthly plan, <b>you won't be eligible for the Discount</b>.</li>
                  </ul>

                </p>
              </div>
              <div className="modalFooter" style={{ display: 'flex' }}>
                <Button
                  btnText="Proceed with Monthly"
                  btnClass="btn btn-secondary"
                  onClick={() => {
                    setCouponWarningModal(false);
                  }}
                >
                </Button>
                <Button
                  btnText="Switch to Annual"
                  btnClass="btn btn-primary"
                  onClick={() => {
                    setBillingDuration('annually')
                    setCouponWarningModal(false);
                  }}
                ></Button>
              </div>
            </div>
          </Modalwrapper>
          <Col sm={12} md={12} lg={12} className="no-padding-sm">
            <TransitionEaseInTwo>
              {
                billingDuration === 'annually' ? (
                  <Swiper {...params(2)} activeSlideKey="1">
                    <div key="0">
                      <div
                        className={cx("plan-benefit-card", {
                          active: plan === "basePlan",
                        })}
                        onClick={() => {
                          onClickPlanCard("basePlan");
                        }}
                      >
                        <PlanBenefitCard

                          showExclusive={true}
                          onClickBtn={onChoosePlan}
                          title={getTitle('base')}
                          price={getPrice('base')}
                          planId={basePlanId}
                          exclusiveFeatures={
                            "Weekly recipes\n Weekly workouts \n Video library access \n Ad-free experience \n Community forum access"
                          }
                          baseFeatures={
                            null
                          }
                          isLoading={registerStatus.isRegistering}
                          billingDuration={billingDuration}
                          percentOff={
                            unifiedRegisterState.coupon.activationSuccess
                              ? unifiedRegisterState.coupon.data.percent_off
                              : null
                          }
                          trialLength={
                            unifiedRegisterState.coupon.activationSuccess
                              ? unifiedRegisterState.coupon.data.trial_length
                              : null
                          }
                          isCouponed={unifiedRegisterState.coupon.activationSuccess}
                        />
                      </div>
                    </div>
                    <div key="1">
                      <div
                        className={cx(
                          "plan-benefit-card plan-benefit-card-with-label",
                          {
                            active: plan === "premiumPlan",
                          }
                        )}
                        onClick={(e) => {
                          onClickPlanCard("premiumPlan");
                        }}
                      >
                        <PlanBenefitCard
                          premium={true}
                          showExclusive={true}
                          onClickBtn={onChoosePlan}
                          title={getTitle('premium')}
                          price={getPrice('premium')}
                          planId={premiumPlanId}
                          baseFeatures={"All of basics plan"}
                          exclusiveFeatures={
                            "Weekly meal plans\n Personal meal plan builder\nShoppable grocery list\nDaily nutrition totals"
                          }
                          isLoading={registerStatus.isRegistering}
                          billingDuration={billingDuration}
                          percentOff={
                            unifiedRegisterState.coupon.activationSuccess
                              ? unifiedRegisterState.coupon.data.percent_off
                              : null
                          }
                          trialLength={
                            unifiedRegisterState.coupon.activationSuccess
                              ? unifiedRegisterState.coupon.data.trial_length
                              : null
                          }
                          isCouponed={unifiedRegisterState.coupon.activationSuccess}
                        />
                      </div>
                    </div>


                  </Swiper>
                ) : (
                  <Swiper {...params(3)} activeSlideKey="1">
                    <div key="0">
                      <div
                        className={cx("plan-benefit-card", {
                          active: plan === "basePlan",
                        })}
                        onClick={() => {
                          onClickPlanCard("basePlan");
                        }}
                      >
                        <PlanBenefitCard
                          showExclusive={true}
                          onClickBtn={onChoosePlan}
                          title={getTitle('base')}
                          price={getPrice('base')}
                          planId={basePlanId}
                          exclusiveFeatures={
                            "Weekly recipes\n Weekly workouts \n Video library access \n Ad-free experience \n Community forum access"
                          }
                          baseFeatures={null}
                          isLoading={registerStatus.isRegistering}
                          billingDuration={billingDuration}
                          percentOff={
                            null
                          }
                          trialLength={
                            null
                          }
                          isCouponed={unifiedRegisterState.coupon.activationSuccess}
                        />
                      </div>
                    </div>
                    <div key="1">
                      <div
                        className={cx(
                          "plan-benefit-card plan-benefit-card-with-label",
                          {
                            active: plan === "premiumPlan",
                          }
                        )}
                        onClick={(e) => {
                          onClickPlanCard("premiumPlan");
                        }}
                      >
                        <PlanBenefitCard
                          showExclusive={true}
                          premium={true}
                          onClickBtn={onChoosePlan}
                          title={getTitle('premium')}
                          price={getPrice('premium')}
                          planId={premiumPlanId}
                          baseFeatures={"All of basics plan"}
                          exclusiveFeatures={
                            "Weekly meal plans\n Personal meal plan builder\nShoppable grocery list\nDaily nutrition totals"
                          }
                          isLoading={registerStatus.isRegistering}
                          billingDuration={billingDuration}
                          percentOff={
                            null
                          }
                          trialLength={
                            null
                          }
                          isCouponed={unifiedRegisterState.coupon.activationSuccess}
                        />
                      </div>
                    </div>
                  </Swiper>
                )
              }

            </TransitionEaseInTwo>
          </Col>
        </Row>
      </section>
    </>
  );
};
const mapStateToProps = (state) => ({
  registerStatus: getRegisterStatus(state),
  plans: state.getIn(["essentials", "plans"]),
  unifiedRegisterState: state.getIn(["unifiedRegister"]),
  initiatedEmail: getInitiatedEmail(state),
});
const dispatchActionToProps = (dispatch) => ({
  assignPlanUnified: bindActionCreators(assignPlanUnified, dispatch),
  showToastMessage: bindActionCreators(showToastMessage, dispatch),
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
  couponActivateInitiate: bindActionCreators(couponActivate.initiate, dispatch),
  couponActivateSuccess: bindActionCreators(couponActivate.success, dispatch),
  couponActivateFailure: bindActionCreators(couponActivate.failure, dispatch),
  setPlans: bindActionCreators(setPlans, dispatch),
});
export default connect(
  mapStateToProps,
  dispatchActionToProps
)(toJS(ChoosePlan));
