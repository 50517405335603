import React, { useState } from "react";

import Modalwrapper from "../../common/modal";
import Selector from "../../common/selector";
import Button from "../../common/button";
import TextArea from "../../common/formElements/textarea";
import Input from "../../common/formElements/input";

const capitalize = s => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const CustomMealPlan = ({ addCustomRecipeMealPlanHandler, addCustomWorkoutMealPlanHandler, onCloseCustomModal }) => {
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [notes, setNotes] = useState("");

  const [objectType, setObjectType] = useState("recipe");
  const [linkError, setLinkError] = useState(false);

  function handleLinkChange(e) {
    const value = e.target.value;
    const linkPattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g;
    const isLinkValid = linkPattern.test(value);

    isLinkValid || value.length === 0 ? setLinkError(false) : setLinkError(true);
    setLink(value);
  }

  const handleCustomItemConfirm = () => {
    objectType === "recipe"
      ? addCustomRecipeMealPlanHandler(title, link, notes)
      : addCustomWorkoutMealPlanHandler(title, link, notes);
  };

  return (
    <Modalwrapper minHeight="370px" maxWidth="500px" isOpen={true} onRequestClose={onCloseCustomModal}>
      <div className="modalWrapper custom-modal-wrapper">
        <div className="modalHeader">
          <h4>Add custom</h4>
        </div>
        <div className="modalBody custom-meal-wrapper">
          <div className="custom-block">
            <form>
              <div className="selector">
                <span className="static-value">Custom</span>
                <Selector
                  selectorList={[
                    { title: "Recipe", value: "recipe" },
                    { title: "Workout", value: "workout" }
                  ]}
                  onSelectValue={value => setObjectType(value)}
                  value={objectType}
                />
              </div>
              <div className="form-text">
                <Input
                  type="text"
                  placeholder={`Type ${objectType} name`}
                  className="inputField"
                  label="Title"
                  value={title}
                  onChange={e => setTitle(capitalize(e.target.value))}
                />
              </div>
              <div className="form-text">
                <Input
                  type="text"
                  placeholder="Add Link to parse"
                  className="inputField"
                  label="Link"
                  value={link}
                  onChange={handleLinkChange}
                  errorMessage={linkError ? "Not a valid link" : null}
                />
              </div>
              <div className="modalFooter">
                <Button
                  type="button"
                  disabled={title.length === 0 || linkError}
                  btnClass="primary btn-full"
                  btnText="Confirm"
                  onClick={handleCustomItemConfirm}
                />
                <Button btnClass="secondary btn-full btn-bp-sm" btnText="Cancel" onClick={() => onCloseCustomModal()} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modalwrapper>
  );
};

export default CustomMealPlan;
