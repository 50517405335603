import React, { useState, useEffect } from 'react';
import isNil from "lodash/isNil";

import cx from 'classnames'
import Selector from '../common/selector'
import { List } from 'immutable';
import { themeTextRed } from '../../helpers/theme';


let listener;

const AccountTab = ({
  history,
  location,
  notificationState
}) => {
  const [responsive, setResponsive] = useState(false);

  // sets the Tablists
  useEffect(() => {
    if (window.innerWidth <= 767) {
      setResponsive(true)
    } else if (window.innerWidth > 767) {
      setResponsive(false)
    }
    if (listener) {
      window.removeEventListener("resize")
    }

    listener = window.addEventListener("resize", () => {
      if (window.innerWidth <= 767) {
        setResponsive(true)
      } else if (window.innerWidth > 767) {
        setResponsive(false);
      }
    });
  }, [])

  const renderDesktop = () => {

    return (
      <>
        <div className="tab-header">
          <div className="tab-items half-width">
            {
              false ? (
                <Placeholder
                  styles={{ height: 20, width: 600 }}
                />
              ) : (
                <ul>
                  <li
                    className={cx("each-tab-header", { active: history.location.pathname === '/account' })}
                    onClick={() => history.push('/account')}
                  >
                    Account Settings
                  </li>
                  <li
                    className={cx("each-tab-header", { active: history.location.pathname === '/account/membership' })}
                    onClick={() => history.push('/account/membership')}
                  >
                    Membership Info
                  </li>
                  <li
                    className={cx("each-tab-header", { active: history.location.pathname === '/account/referral' })}
                    onClick={() => history.push('/account/referral')}
                  >
                    Referral Info
                  </li>
                  <li
                    className={cx("each-tab-header", { active: history.location.pathname === '/account/updates' })}
                    onClick={() => history.push('/account/updates')}
                  >
                    Updates
                    <span
                      style={{
                        color: themeTextRed,
                        fontWeight: '400'
                      }}
                      className="count">&nbsp;{(notificationState.unread_notifications.length > 0) && `(${notificationState.unread_notifications.length})`}</span>
                  </li>
                </ul>
              )
            }
          </div>
          <div
            className="tab-action"
            onClick={() => {
              console.log('7')
              localStorage.removeItem('token')
              window.location = "/login"
            }}
          >
            <span>
              Logout
            </span>
          </div>
        </div>
      </>
    )
  }

  const renderResponsive = () => {

    return (
      <div className="login-selector">
        <div className="selector">
          <span className="static-value">Show</span>
          <Selector
            selectorList={[{
              title: 'Account Settings',
              value: '/account'
            },
            {
              title: 'Membership Info',
              value: '/account/membership'
            },
            {
              title: 'Referral Info',
              value: '/account/referral'
            },
            {
              title: 'Updates',
              value: '/account/updates'
            },
            ]}
            onSelectValue={(value) => history.push(value)}
            defaultValue={location.pathname}
          />
        </div>
        <div
          className="tab-action"
          onClick={() => {
            console.log('8')
            localStorage.removeItem('token')
            window.location = "/login"
          }}
        >
          <span>
            Logout
          </span>
        </div>
      </div>
    )
  }
  return (
    <div className="tab-wrapper">
      {
        responsive ? renderResponsive() : renderDesktop()
      }
    </div>
  )
}


export default AccountTab