import React, { useEffect, useState } from "react";

import cx from "classnames";
import { Row, Col } from "react-flexbox-grid";
import map from "lodash/map";
import includes from "lodash/includes";
import concat from "lodash/concat";
import axios from "axios";

import { baseUrl, videos } from "../../../helpers/api";

const horizontalList = (isAdmin, activeRecipe, activeWorkout, activeBlog) => {
    const basicSet = [
        {
            title: "Signup",
            href: "/pricing",
        },
        {
            title: "Signin",
            href: "/login",
        },

    ];

    let additionalSet = [];



    const finalSet = [

    ];

    return concat(concat(basicSet, additionalSet), finalSet);
};

const SignupDropdown = ({
    isAdmin,
    history,
    activeRecipe,
    activeWorkout,
    activeBlog,
    activeHoveredEntry,
}) => {
    const [videoAdminUrl, setVideoAdminUrl] = useState(null);

    useEffect(() => {
        if (includes(window.location.href, "/video/")) {
            axios({
                method: "GET",
                url: videos.get(window.location.href.split("video/")[1]),
                headers: {
                    Authorization: `Token token="${localStorage.getItem("token")}"`,
                },
            }).then((response) => {
                if (response && response.data) {
                    setVideoAdminUrl(
                        `${baseUrl}/admin/videos/${response.data.videos.id}/edit`
                    );
                }
            });
        }
    }, []);

    return (
        <ul className="profile-dropdown-list">
            {map(
                horizontalList(isAdmin, activeRecipe, activeWorkout, activeBlog),
                (item) => {
                    return (
                        <li>
                            <a
                                href={item.href}
                                disabled={!item.href}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (item.type === "direct") {
                                        if (item.logout) {
                                            console.log('3')
                                            localStorage.removeItem("token");
                                            window.location = "/login";
                                        } else {
                                            window.open(item.href || videoAdminUrl, "_blank");
                                        }
                                    } else {
                                        history.push(item.href);
                                    }
                                }}
                            >
                                {item.title}
                            </a>
                        </li>
                    );
                }
            )}
        </ul>
    );
};

export default SignupDropdown;
