import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import cx from 'classnames';
import axios from 'axios';
import { baseUrl } from "../../../helpers/api";
import { findIndex, isEmpty, isNil, map, filter, set } from "lodash";
import moment from "moment";
import { Row, Col } from "react-flexbox-grid";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

import AverageData from "./averageData";
import PlanCalendar from "./PlanCalendar";
import MealPlanList from "./MealPlanList";
import FeaturedPlan from "./featuredPlan";
import ResponsiveFeaturedPlan from "./responsiveFeaturedPlan";
import AddRecipeModal from "./addRecipeModal";
import AddWorkoutModal from "./addWorkoutModal";
import PremadeModal from "./premadeModal";
import SaveDayModal from "./saveDayModal";
import AddSaveDayModal from "./addSaveDayModal";
import CustomMealPlan from "./CustomMealPlan";

import Button from "../../common/button";
import ModalWrapper from "../../common/modal";
import { TransitionOne, TransitionTwo } from "../../common/transitions";

import getUserPlanType from "../../../helpers/getUserPlanType";
import { useMealPlanHook } from "../../../helpers/useMealPlanHook";

import ModalImg from "../../images/modalpic.png";
import Loader from "../../images/loader";
import ParseIngredient from "./ParseIngredient";


const mealPlanImages = {
  'Kid Friendly': 'https://s3.amazonaws.com/v3-media.dashingdish.com/recipe_photos/images/000/003/498/full_sm/DSCF9743.JPG?1701444379',
  'Clean Eating Dinners': 'https://s3.amazonaws.com/v3-media.dashingdish.com/recipe_photos/images/000/003/423/full_sm/DSCF7051_2_2.jpg?1694097777',
  'Gluten Free Dinners': 'https://s3.amazonaws.com/v3-media.dashingdish.com/recipe_photos/images/000/003/536/full_sm/DSCF9682.JPG?1702043054',
  'Quick Easy Dinners': 'https://s3.amazonaws.com/v3-media.dashingdish.com/recipe_photos/images/000/003/466/full_sm/DSCF8357_2.JPG?1695733423',
  'Slow Cooker Dinners': 'https://s3.amazonaws.com/v3-media.dashingdish.com/recipe_photos/images/000/003/415/full_sm/DSCF8053_2.JPG?1693251705',
  'Complete Meal Plan': 'https://s3.amazonaws.com/v3-media.dashingdish.com/recipe_photos/images/000/002/795/tall_235/DSCF4842_2.JPG?1633958630'
}
let allMacros = [
  {
    id: 1,
    name: "Calories",
    key: "calories",
  },
  {
    id: 2,
    name: "SmartPoints®",
    key: "smartPoints",
  },
  {
    id: 3,
    name: "Protein",
    key: "protein",
  },
  {
    id: 4,
    name: "Sugar",
    key: "sugar",
  },
  {
    id: 5,
    name: "Carbs",
    key: "carbs",
  },
  {
    id: 6,
    name: "Fat",
    key: "fat",
  },
  {
    id: 7,
    name: "Freestyle Points",
    key: "freestylePoints",
  },
  {
    id: 8,
    name: "Saturated Fat",
    key: "saturatedFat",
  },
  {
    id: 9,
    name: "Fiber",
    key: "fiber",
  },
  {
    id: 10,
    name: "WWP",
    key: "wwp",
  },
];

const mealPlan = (props) => {
  const [responsive, setResponsive] = useState(false);
  const [showNavList, setShowNavList] = useState(true);
  const [windowWidth, setWindowWidth] = useState(undefined);
  const [scrollTopButton, showScrollTopButton] = useState(false);
  const [showMealplanChooseModal, setShowMealplanChooseModal] = useState(false);
  const [chosenMealPlanIsFetching, setChosenMealPlanIsFetching] = useState(false);
  const [calendarType, setCalendarType] = useState(props.calendarView);
  const [selectedChosenMealplan, setSelectedChosenMealplan] = useState([]);
  const [rState, setRState] = useState({
    mealPlansFetched: false,
    mealPlans: []
  });

  let mealPlanSlug = null;
  let isMealPlanListOpen = false;
  let selectedCategory = null;
  let sortorder = "desc";

  if (props.location && props.location.search) {
    const splitItems = props.location.search.split("=");

    if (splitItems && splitItems.length > 0) {
      if (splitItems[0] === "?meal-plans" && splitItems[1] === "true") {
        mealPlanSlug = null;
        isMealPlanListOpen = true;
      } else if (splitItems[0] === "?pick-your-plan" && splitItems[1] === "true") {

      } else {
        const newSplitItems = props.location.search.split("&");
        if (newSplitItems.length > 1) {
          const category = newSplitItems[1].split("=");
          if (category[0] === "category" && category[1].length > 0) {
            isMealPlanListOpen = true;
            mealPlanSlug = null;
            selectedCategory = category[1];
          } else if (
            category[0] === "order" &&
            category[1] === "most-popular"
          ) {
            mealPlanSlug = null;
            isMealPlanListOpen = true;
            sortorder = "favorite";
          } else {
            mealPlanSlug = null;
            isMealPlanListOpen = true;
          }
        } else {
          isMealPlanListOpen = false;
          mealPlanSlug = splitItems[1];
        }
      }
    }
  }

  const [state, dispatch] = useMealPlanHook({
    token: localStorage.getItem("token"),
    sortorder,
    selectedCategory,
  });

  const [isIngredientSelect, setIsIngredientSelect] = useState(false);
  const [customLink, setCurrentCustomLink] = useState('');
  // Change calendar view after startup
  useEffect(() => {
    setCalendarType(props.calendarView);
    const splitItems = props.location.search.split("=");
    if (splitItems[0] === "?pick-your-plan" && splitItems[1] === "true") {
      setShowMealplanChooseModal(true);
    }
  }, [props.calendarView]);

  const [macroState, setMacroState] = useState({
    showDropdown: false,
    showChooseModal: false,
  });

  useEffect(() => {
    if (window.innerWidth <= 991) {
      setShowNavList(false);
      setWindowWidth(window.innerWidth);
      setResponsive(true);
    } else if (window.innerWidth > 991) {
      setShowNavList(true);
      setWindowWidth(window.innerWidth);
      setResponsive(false);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 991) {
        setShowNavList(false);
        setWindowWidth(window.innerWidth);
        setResponsive(true);
      } else if (window.innerWidth > 991) {
        setShowNavList(true);
        setWindowWidth(window.innerWidth);
        setResponsive(false);
      }
    });
  }, []);

  const handleScroll = (a, b, c) => {
    if (a.target.scrollingElement.scrollTop > 10) {
      showScrollTopButton(true);
    } else {
      showScrollTopButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
  }, []);

  // Close modal when

  useEffect(() => {
    if (props.progressStatus.addCustomMealPlanToCalendar.status === "remove") {
      props.history.push("/calendar");
      setPremadeModalState({
        show: false,
        type: null,
        index: {
          slug: null,
          id: null,
          item: null,
        },
        calendarDate: null,
      });
    }
  }, [props.progressStatus.addCustomMealPlanToCalendar.isProgressing]);

  const [addRecipeState, setAddRecipeState] = useState({
    show: false,
    activeDate: null,
    activeObjectType: null,
    addedIds: [],
  });

  const [addWorkoutState, setAddWorkoutState] = useState({
    show: false,
    activeDate: null,
    activeObjectType: null,
    addedIds: [],
  });

  const [customState, setCustomState] = useState({
    show: false,
    activeDate: null,
    activeObjectType: null,
  });

  const [moveModal, setMoveModalState] = useState({
    show: false,
    activeMeal: {},
    fromDate: null,
  });

  const [premadeModal, setPremadeModalState] = useState({
    show: mealPlanSlug || isMealPlanListOpen ? true : false,
    type: isMealPlanListOpen ? "list" : "index",
    // type: "list",
    index: {
      slug: mealPlanSlug,
      id: null,
      item: {
        id: null,
        name: null,
        slug: mealPlanSlug,
        description: "",
        created_at: null,
      },
    },
    favorite: false,
    publish_date: null,
    calendarDate: "2020-02-05",
    replaceOldItems: false,
  });

  const [saveDayModal, setSaveDayState] = useState({
    show: false,
    activeDate: null,
  });

  const [addSavedDayModalState, setAddSavedDayState] = useState({
    show: false,
    activeDate: null,
  });

  const yOffset = responsive ? -288 : -64;

  // Handles change in category / filter of premade modal
  useEffect(() => {
    if (selectedCategory) {
      // Gluten free refers to all
      dispatch({
        type: "UPDATE_CATEGORY",
        payload: {
          previouslySelected: false,
          appCategory: selectedCategory,
          initial: true,
        },
      });

      setPremadeModalState({
        ...premadeModal,
        show: true,
        type: "list",
        index: {
          slug: null,
          id: null,
          item: null,
        },
        replaceOldItems: false,
      });
    } else {
      // dispatch({
      //   type: "UPDATE_CATEGORY",
      //   payload: {
      //     previouslySelected: false,
      //     appCategory: null
      //   }
      // });
      // setPremadeModalState({
      //   ...premadeModal,
      //   show: true,
      //   type: "list",
      //   index: {
      //     slug: null,
      //     id: null,
      //     item: null
      //   },
      //   replaceOldItems: false
      // });
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (isMealPlanListOpen) {
      dispatch({
        type: "CHANGE_ORDER",
        payload: {
          order: sortorder,
          force: sortorder === "favorite",
        },
      });
      setPremadeModalState({
        ...premadeModal,
        show: true,
        type: "list",
        index: {
          slug: null,
          id: null,
          item: null,
        },
        replaceOldItems: false,
      });
    }
  }, [sortorder]);
  useEffect(() => {
    const token = localStorage.getItem('token');

    axios({
      method: "GET",
      headers: {
        Authorization: `Token token="${token}"`
      },
      url: `${baseUrl}/api/favoritemealplans`,
    })
      .then((response) => {
        let activeMealPlansSelected = [];
        const filteredData = response.data.all_fmp.map((f) => {
          if (response.data.favorite_mealplans.findIndex(fm => fm.favoritemealplans_id === f.id) !== -1) {
            activeMealPlansSelected.push(f.title);
            return {
              ...f,
              active: true
            }
          } else {
            return {
              ...f,
              active: false
            }
          }
        });
        setSelectedChosenMealplan(activeMealPlansSelected);

        setRState({
          mealPlans: filteredData,
          mealPlansFetched: true
        })
          .catch((e) => {
            console.log('filtered data ', e)
            pushToSentry(e);
          });
      });


    props.appEssentialFetch({
      meta: {
        feature: "calendar",
      },
    });
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    // set timeout to let the calendar load
    setTimeout(() => {
      scroller.scrollTo(
        moment()
          .startOf("day")
          .format("YYYY-MM-DD"),
        {
          duration: 500,
          smooth: true,
          offset: yOffset,
        }
      );
    }, 500);
  }, [props.calendarListData.length]);

  const onSubmitChosenModal = () => {
    const mps = selectedChosenMealplan.map((mp) => {

      const foundmp = rState.mealPlans.find(p => p.title === mp);

      return `${foundmp.id}`;
    })

    setChosenMealPlanIsFetching(true);
    const token = localStorage.getItem('token');
    axios({
      method: "POST",
      headers: {
        Authorization: `Token token="${token}"`
      },
      data: {
        mps,
      },
      url: `${baseUrl}/api/favoritemealplans`,
    })
      .then(() => {
        setChosenMealPlanIsFetching(false);
        setShowMealplanChooseModal(false)
      })
  }

  const {
    calendarSettings,
    calendarDates,
    changeCalendarActiveDate,
    changeCalendarActiveMonth,
    calendarListData,
    deleteMealFromMealPlan,
    averageData,
    duplicateMeal,
    fetchStatus,
    createFavourite,
    deleteFavourite,
    isAuthenticated,
  } = props;

  const changeMonth = (type) => {
    changeCalendarActiveMonth(type);
  };

  const changeActiveDay = (activeDate) => {
    changeCalendarActiveDate(activeDate);
  };

  const addMealHandler = (item) => {
    let meal = "breakfast";
    if (addRecipeState.show) {
      if (
        findIndex(addRecipeState.addedIds, (addedId) => item.id === addedId) ===
        -1
      ) {
        props.addMealInMealPlan(
          item.id,
          addRecipeState.activeDate,
          meal,
          item.objectType
        );
        const addedIds = Array.isArray(addRecipeState.addedIds)
          ? addRecipeState.addedIds
          : [];
        setAddRecipeState({
          ...addRecipeState,
          addedIds: [...addedIds, item.id],
        });
      }
    } else {
      if (
        findIndex(
          addWorkoutState.addedIds,
          (addedId) => item.id === addedId
        ) === -1
      ) {
        props.addMealInMealPlan(
          item.id,
          addWorkoutState.activeDate,
          meal,
          item.objectType
        );
        const addedIds = Array.isArray(addWorkoutState.addedIds)
          ? addWorkoutState.addedIds
          : [];
        setAddWorkoutState({
          ...addWorkoutState,
          addedIds: [...addedIds, item.id],
        });
      }
    }
  };

  const deleteMealHandler = (meal_id) => {
    deleteMealFromMealPlan(meal_id);
  };

  const openMoveModal = (activeMeal, fromDate) => {
    setMoveModalState({
      show: true,
      activeMeal,
      fromDate,
    });
  };

  const moveMealHandler = (mealId, toDate) => {
    if (toDate !== moveModal.fromDate) {
      props.moveMealInMealPlan(mealId, toDate, moveModal.fromDate);
      setMoveModalState({
        show: false,
        activeMeal: {},
        fromDate: null,
      });
    }
  };

  const addCustomRecipeMealPlanHandler = (title, link, notes) => {
    props.addCustomRecipeToMealPlan(customState.activeDate, title, link, notes);
    setCurrentCustomLink(link);
    setCustomState({
      show: false,
      activeDate: null,
      activeObjectType: null,
    });

    setIsIngredientSelect(true);
  };

  const addCustomWorkoutMealPlanHandler = (title, link, notes) => {
    props.addCustomRecipeToMealPlan(customState.activeDate, title, link, notes);

    setCustomState({
      show: false,
      activeDate: null,
      activeObjectType: null,
    });
  };

  const handleSubmitChooseDate = (
    startDate,
    replaceOldItems,
    addIngredientsToGroceryList,
    servingSize
  ) => {
    props.addMealPlanToCalendar(
      premadeModal.index.id,
      startDate,
      replaceOldItems,
      premadeModal.index.item.days,
      addIngredientsToGroceryList,
      servingSize
    );
    setPremadeModalState({
      ...premadeModal,
      show: false,
      type: null,
      index: {
        slug: null,
        id: null,
        item: null,
      },
      replaceOldItems: false,
    });
  };
  if (fetchStatus.isCalendarFetching) {
    return (
      <div className="container">
        <div className="preLoader">
          <Loader></Loader>
        </div>
      </div>
    );
  }

  const handleOnSaveCalendarDate = (customName) => {
    props.saveCalendarDay(saveDayModal.activeDate, customName);
    setSaveDayState({
      show: false,
      activeDate: null,
    });
  };

  const handleAddSavedDay = (itemId) => {
    props.addSavedDay(addSavedDayModalState.activeDate, itemId);
    setAddSavedDayState({
      show: false,
      activeDate: null,
    });
  };

  const filterBy = (meals, type) =>
    filter(meals, (meal) => !isEmpty(meal[type]));
  const daysContainingRecipes = map(
    filter(
      calendarListData,
      (listData) => filterBy(listData.meals, "recipe").length > 0
    ),
    (listData) => listData.date
  );
  const daysContainingWorkouts = map(
    filter(
      calendarListData,
      (listData) => filterBy(listData.meals, "workout").length > 0
    ),
    (listData) => listData.date
  );
  const isPremiumPlan = () =>
    !isNil(props.userDetails) &&
    !isNil(props.userDetails.details) &&
    !isEmpty(props.userDetails.details) &&
    (getUserPlanType(props.userDetails) === "premiumPlan" ||
      getUserPlanType(props.userDetails) === "grandfather");

  const renderCustomMealPlan = (item, index) => {
    return map(rState.mealPlans, (item, index) => {
      return (
        <div
          className={cx("plans-card", {
            selected: (selectedChosenMealplan.findIndex(mp => mp === item.title) !== -1)
          })}
          onClick={() => {
            if ((selectedChosenMealplan.findIndex(mp => mp === item.title) !== -1)) {
              setSelectedChosenMealplan([...selectedChosenMealplan].filter(mp => mp !== item.title))
            } else {
              setSelectedChosenMealplan([...selectedChosenMealplan, item.title])
            }

          }}
          key={item.title}
        >
          <figure>
            <img
              src={mealPlanImages[item.title]}
              className="img_fluid"
            ></img>
          </figure>
          <div className="plans-card-name">
            <h6>{item.title}</h6>
            {/* <small>{item.count}&nbsp;Meal Plans</small> */}
          </div>
          {/* <div className="plans-card outline-1"></div>
          <div className="plans-card outline-2"></div> */}
        </div>
      );
    });
  };

  return (
    <section className="mealplan">
      <Helmet>
        <title>Calendar | Dashing Dish</title>
        <meta name="description" content="Dashingdish Meal Plan page" />
      </Helmet>
      <div className="container no-relative">
        <Row className="mealplan-wrapper no-margin">
          <TransitionOne>
            <Col sm={12} md={12} lg={5} className="planCalendar no-padding">
              <div className="planWrapper">
                <div className="sticky-wrapper">
                  <PlanCalendar
                    calendarSettings={calendarSettings}
                    calendarDates={calendarDates}
                    changeMonth={changeMonth}
                    changeActiveDay={changeActiveDay}
                    daysContainingRecipes={daysContainingRecipes}
                    daysContainingWorkouts={daysContainingWorkouts}
                    responsive={responsive}
                    yOffset={yOffset}
                    calendarType={calendarType}
                    setCalendarType={setCalendarType}
                  />
                  <AverageData
                    defaultCalendarType={props.calendarView}
                    averageData={averageData}
                    allMacros={allMacros}
                    updateCalendarSettings={props.updateCalendarSettings}
                    setMacros={props.setMacros}
                    macroState={macroState}
                    setMacroState={setMacroState}
                    setCalendarType={setCalendarType}
                    calendarType={calendarType}
                  />
                  {isPremiumPlan() && (
                    <FeaturedPlan
                      openPreferredPlanChoose={() => setShowMealplanChooseModal(true)}
                      setMealPlanModalState={() => {
                        setPremadeModalState({
                          ...premadeModal,
                          show: true,
                          type: "list",
                          index: {
                            slug: null,
                            id: null,
                            item: null,
                          },
                          replaceOldItems: false,
                          calendarDate: moment()
                            .startOf("day")
                            .format("YYYY-MM-DD"),
                        });
                        props.history.push("/calendar?meal-plans=true");
                      }}
                      moveToIndex={(item) => {
                        props.history.push(`/calendar?meal-plan=${item.slug}`);
                        setPremadeModalState({
                          ...premadeModal,
                          show: true,
                          type: "index",
                          index: {
                            slug: item.slug,
                            id: item.id,
                            item,
                          },
                          replaceOldItems: false,
                          calendarDate: moment()
                            .startOf("day")
                            .format("YYYY-MM-DD"),
                        });
                      }}
                      addSentryError={props.addSentryError}
                    />
                  )}
                </div>
              </div>
              {responsive && (
                <ResponsiveFeaturedPlan
                  setMealPlanModalState={() => {
                    setPremadeModalState({
                      ...premadeModal,
                      show: true,
                      type: "list",
                      index: {
                        slug: null,
                        id: null,
                        item: null,
                      },
                      calendarDate: moment()
                        .startOf("day")
                        .format("YYYY-MM-DD"),
                      replaceOldItems: false,
                    });
                  }}
                  moveToIndex={(item) => {
                    props.history.push(`/calendar?meal-plan=${item.slug}`);
                    setPremadeModalState({
                      ...premadeModal,
                      show: true,
                      type: "index",
                      index: {
                        slug: item.slug,
                        id: item.id,
                        item,
                      },
                      replaceOldItems: false,
                    });
                  }}
                  addSentryError={props.addSentryError}
                />
              )}
            </Col>
          </TransitionOne>
          <TransitionTwo>
            <Col sm={12} md={12} lg={7} className="mealPlanList no-padding">
              <MealPlanList
                userDetails={props.userDetails}
                history={props.history}
                calendarListData={calendarListData}
                updateRecipeModal={setAddRecipeState}
                updateWorkoutModal={setAddWorkoutState}
                deleteMeal={deleteMealHandler}
                duplicateMeal={duplicateMeal}
                openMoveModal={openMoveModal}
                moveMealInMealPlan={props.moveMealInMealPlan}
                addIngredientsOfDayToGroceryList={
                  props.addIngredientsOfDayToGroceryList
                }
                addIngredientsOfRangeToGroceryList={
                  props.addIngredientsOfRangeToGroceryList
                }
                clearMealPlanDay={props.clearMealPlanDay}
                setMacros={props.setMacros}
                macroState={macroState}
                setMacroState={setMacroState}
                updateCustomModal={setCustomState}
                moveToPremadeMealPlanList={(calendarDate) => {
                  setPremadeModalState({
                    ...premadeModal,
                    show: true,
                    type: "list",
                    index: {
                      slug: null,
                      id: null,
                      item: null,
                    },
                    replaceOldItems: false,
                    calendarDate,
                  });
                }}
                setSaveDayState={setSaveDayState}
                setAddSavedDayState={setAddSavedDayState}
                progressStatus={props.progressStatus}
              />
              <div className="list-action">
                {
                  <Button
                    btnClass="primary btn-sm floating"
                    btnText="Today"
                    onClick={() => {
                      const moveTo = moment()
                        .startOf("day")
                        .format("YYYY-MM-DD");
                      scroller.scrollTo(moveTo, {
                        duration: 500,
                        offset: yOffset,
                        smooth: true,
                      });
                    }}
                  />
                }
              </div>
            </Col>
          </TransitionTwo>
        </Row>
        {isAuthenticated && addRecipeState.show && (
          <AddRecipeModal
            {...props}
            show={addRecipeState.show}
            isAuthenticated={props.isAuthenticated}
            activeDate={addRecipeState.activeDate}
            activeObjectType={addRecipeState.activeObjectType}
            addMeal={addMealHandler}
            addCustomRecipeMealPlanHandler={addCustomRecipeMealPlanHandler}
            isAuthenticated={isAuthenticated}
            addedIds={addRecipeState.addedIds}
            updateActiveDate={(activeDate) => {
              setAddRecipeState({
                ...addRecipeState,
                activeDate: activeDate,
                addedIds: [],
              });
            }}
            onRequestClose={() =>
              setAddRecipeState({
                show: false,
                activeDate: null,
                activeObjectType: null,
                addedIds: [],
              })
            }
          />
        )}
        {isAuthenticated && addWorkoutState.show && (
          <AddWorkoutModal
            {...props}
            show={addWorkoutState.show}
            isAuthenticated={props.isAuthenticated}
            activeDate={addWorkoutState.activeDate}
            activeObjectType={addWorkoutState.activeObjectType}
            addMeal={addMealHandler}
            addCustomWorkoutMealPlanHandler={addCustomWorkoutMealPlanHandler}
            isAuthenticated={isAuthenticated}
            addedIds={addWorkoutState.addedIds}
            updateActiveDate={(activeDate) => {
              setAddWorkoutState({
                ...addWorkoutState,
                activeDate: activeDate,
                addedIds: [],
              });
            }}
            onRequestClose={() =>
              setAddWorkoutState({
                show: false,
                activeDate: null,
                activeObjectType: null,
                addedIds: [],
              })
            }
          />
        )}
        {isAuthenticated && saveDayModal.show ? (
          <SaveDayModal
            setSaveDayState={setSaveDayState}
            saveDayModal={saveDayModal}
            onRequestClose={() =>
              setSaveDayState({
                show: false,
                activeDate: null,
              })
            }
            saveCalendarDay={handleOnSaveCalendarDate}
          />
        ) : null}

        {isAuthenticated && addSavedDayModalState.show && (
          <AddSaveDayModal
            setAddSavedDayState={setAddSavedDayState}
            addSavedDayModalState={addSavedDayModalState}
            onAddSaveDay={handleAddSavedDay}
            onRequestClose={() =>
              setAddSavedDayState({
                show: false,
                activeDate: null,
              })
            }
          />
        )}
        {isAuthenticated && customState.show ? (
          <CustomMealPlan
            addCustomRecipeMealPlanHandler={addCustomRecipeMealPlanHandler}
            addCustomWorkoutMealPlanHandler={addCustomWorkoutMealPlanHandler}
            onCloseCustomModal={() => {
              setCustomState({
                ...customState,
                show: false,
                activeDate: null,
              })

            }

            }
          />
        ) : null}
        {
          (isAuthenticated && isIngredientSelect) ? (
            <ParseIngredient
              customLink={customLink}
              onCloseCustomModal={() => {
                setIsIngredientSelect(false);
              }}
              addToGroceryList={props.addAllToGroceryList}
            />
          ) : null
        }
        {isAuthenticated && premadeModal.show ? (
          <PremadeModal
            show={premadeModal.show}
            premadeModal={premadeModal}
            handleSubmitChooseDate={handleSubmitChooseDate}
            createFavourite={createFavourite}
            deleteFavourite={deleteFavourite}
            daysContainingRecipes={daysContainingRecipes}
            chosenCalendarDate={premadeModal.calendarDate}
            chosenAddIngredients={premadeModal.addToGroceryList}
            chosenServingSize={premadeModal.servingSize}
            progressStatus={props.progressStatus}
            isAuthenticated={isAuthenticated}
            listState={state}
            listDispatch={dispatch}
            history={props.history}
            moveToList={(item) => {
              setPremadeModalState({
                ...premadeModal,
                show: true,
                type: "list",
                index: {
                  slug: null,
                  id: null,
                  item: null,
                },
                replaceOldItems: false,
              });
              props.history.push("/calendar?meal-plans=true");
            }}
            moveToIndex={(item) => {
              props.history.push(`/calendar?meal-plan=${item.slug}`);
              setPremadeModalState({
                ...premadeModal,
                show: true,
                type: "index",
                index: {
                  slug: item.slug,
                  id: item.id,
                  item,
                },
                replaceOldItems: false,
              });
            }}
            moveToRecipes={(recipes) => {
              setPremadeModalState({
                ...premadeModal,
                show: true,
                type: "recipes",
                index: {
                  ...premadeModal.index,
                  recipes,
                },
              });
            }}
            moveToAddReplaceConfirmation={(
              calendarDate,
              addToGroceryList,
              servingSize
            ) => {
              setPremadeModalState({
                ...premadeModal,
                show: true,
                type: "confirmation",
                calendarDate,
                addToGroceryList,
                servingSize,
              });
            }}
            onRequestClose={() => {
              props.history.push("/calendar");
              dispatch({
                type: "CHANGE_ORDER",
                payload: {
                  order: "desc",
                  clearCategories: true,
                },
              });

              setTimeout(() => {
                setPremadeModalState({
                  ...premadeModal,
                  show: false,
                  type: null,
                  index: {
                    slug: null,
                    id: null,
                    item: null,
                  },
                  replaceOldItems: false,
                });
              }, 50);
            }}
            activeDate={premadeModal.calendarDate}
            updateActiveDate={(activeDate) => {
              setPremadeModalState({
                ...premadeModal,
                calendarDate: activeDate,
                addedIds: [],
              });
            }}
          />
        ) : null}
      </div>

      {showMealplanChooseModal && (
        <ModalWrapper
          isOpen={showMealplanChooseModal}
          onRequestClose={() => setShowMealplanChooseModal(false)}
          showClose={true}
          minHeight="700px"
          maxWidth="900px"
        >
          <div className="modalWrapper">
            <div className="modalHeader choosePlanner-header">

              <div className="header">
                <div className="header-text">
                  <div className="header-title">
                    <h2><b>Choose your Mealplan</b></h2>

                  </div>
                </div>
              </div>

            </div>
            <div className="modalBody">

              <div>
                <section className="plans choose-premade-plans">
                  <div className="container">
                    <div className="plans-cards chooser-cards">
                      {rState.mealPlansFetched && <>{renderCustomMealPlan()}</>}
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div className="modalFooter">
              <Button
                btnClass="primary btn-full"
                btnText={`Choose Mealplans ${selectedChosenMealplan.length > 0 ? `(${selectedChosenMealplan.length})` : ''}`}
                onClick={onSubmitChosenModal}
              />
            </div>
          </div>
        </ModalWrapper>
      )}

      {isAuthenticated && !isPremiumPlan() && (
        <ModalWrapper
          isOpen={true}
          showClose={false}
          minHeight="670px"
          maxWidth="450px"
        >
          <div className="modalWrapper">
            <div className="modalBody choosePlanner">
              <div className="not-premium-user-modal">
                <h2>
                  Save time and money by meal planning <b>like a pro.</b>
                </h2>
                <figure>
                  <img src={ModalImg} className="img_fluid"></img>
                </figure>
              </div>
            </div>
            <div className="modalFooter">
              <Button
                btnClass="primary btn-full"
                btnText="Get started today!"
                isLoading={chosenMealPlanIsFetching}
                onClick={() => {
                  props.history.push("/account/membership");
                }}
              />
            </div>
          </div>
        </ModalWrapper>
      )}
    </section>
  );
};

export default mealPlan;
