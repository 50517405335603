import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet"

import axios from 'axios'
import TabHeader from './tabHeader'
import TabContent from './tabContent'
import Modalwrapper from '../../common/modal'
import Button from '../../common/button'
import LinkButton from '../../common/linkButton'
import CustomInput from '../../common/customInput'

import { faqs as faqsApi } from '../../../helpers/api';
import pushToSentry from '../../../helpers/pushToSentry';

import {
  generateAppEssentialPayload,
} from '../../../helpers/filterConversions';

import {
  TransitionOne, TransitionTwo
} from "../../common/transitions";

const Faq = ({
  faqs,
  appEssentialFetch,
  showToastMessage,
  fetchStatus,
  userDetails,
  addSentryError,
}) => {
  const [activeCategorySlug, setActiveCategorySlug] = useState(null);
  const [activeOpenedFaqSlug, setActiveOpenedFaqSlug] = useState('Dashing Dish Cancellation and Refund Policy');
  const [showAskQuestion, setShowAskQuestion] = useState(false);

  const [modalContentState, setModalContentState] = useState({
    question: '',
    email: '',
    isPosting: false,
  });


  useEffect(() => {
    setModalContentState({
      ...modalContentState,
      email: userDetails.details.email
    })
  }, [userDetails.details.email])

  const hanldeOnAsk = () => {
    setModalContentState({
      ...modalContentState,
      isPosting: true
    })

    axios({
      method: 'POST',
      url: faqsApi.postQuestion(),
      data: {
        sender_name: userDetails.details.name,
        sender_email: userDetails.details.email,
        question: modalContentState.question
      },
    }).then(response => {
      setShowAskQuestion(false)
      setModalContentState({
        ...modalContentState,
        isPosting: false,
        question: ''
      })
      showToastMessage(`✅ Question sent successfully !`, 'success', 'askQuestion')
    }).catch(e => {
      const eventId = pushToSentry(e)
      addSentryError({
        error: e.message,
        eventId
      })
      setShowAskQuestion(false)
      setModalContentState({
        ...modalContentState,
        isPosting: false,
        question: ''
      })
      showToastMessage(`❗️ Error in sending question.`, 'error', 'askQuestion')
    });
  }

  useEffect(() => {
    appEssentialFetch(generateAppEssentialPayload());
  }, [])

  useEffect(() => {
    if (faqs.length > 0) {
      setActiveCategorySlug(faqs[0].category.slug)
    }
  }, [faqs.length])

  return (
    <section className="faqs">
      <Helmet>
        <title>FAQ's | Dashing Dish</title>
        <meta name="description" content="Dashingdish Faqs page" />
      </Helmet>
      <div className="container">
        <div className="faqs-wrapper">
          <h3>Have a question about Dashing Dish?</h3>
          <TransitionOne>
            <div className="faqs-header">
              <TabHeader
                faqs={faqs}
                activeCategorySlug={activeCategorySlug}
                setActiveCategorySlug={setActiveCategorySlug}
                setShowAskQuestion={setShowAskQuestion}
                fetchStatus={fetchStatus}
              />
            </div>
          </TransitionOne>
          <div className="faqs-body">
            <TabContent
              faqs={faqs}
              activeCategorySlug={activeCategorySlug}
              activeOpenedFaqSlug={activeOpenedFaqSlug}
              setActiveOpenedFaqSlug={setActiveOpenedFaqSlug}
              fetchStatus={fetchStatus}
            />
          </div>
          {
            showAskQuestion ? (
              <Modalwrapper
                maxWidth='500px'
                minHeight='600px'
                isOpen={showAskQuestion}
                onRequestClose={() => setShowAskQuestion(false)}
              >
                <div className="modalWrapper">
                  <div className="modalHeader AddnoteHead">
                    <h3> Ask Question </h3>
                  </div>

                  <div className="modalBody AddnoteTextarea">
                    <textarea
                      className='modalInput'
                      placeholder='Type your Question here'
                      value={modalContentState.question}
                      onChange={(e) => {
                        setModalContentState({
                          ...modalContentState,
                          question: e.target.value
                        })
                      }}
                    />
                  </div>

                  <div className="modalFooter">
                    <Button
                      btnClass="primary btn-full"
                      btnText="Send"
                      onClick={hanldeOnAsk}
                      isLoading={modalContentState.isPosting}
                      disabled={modalContentState.question.length === 0}
                    />
                    {/* <Button
                      btnClass="secondary btn-full"
                      btnText={`Cancel`}
                      // additionalClass='btn btn-full'
                      onClick={() => {
                        setShowAskQuestion(false)
                      }}
                    // showGhost
                    /> */}
                    <Button
                      btnClass="secondary btn-full btn-bp-sm"
                      btnText="Cancel"
                      onClick={() => {
                        setShowAskQuestion(false)
                      }}
                    />
                  </div>
                </div>
              </Modalwrapper>
            ) : null
          }
        </div>
      </div>
    </section>
  )
}

export default Faq;